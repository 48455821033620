<template>
    <div>

        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Licenze</h3>
            </div>

            <div class="col-md-2">
                <search-bar @search-name="searchByCode($event)" :placeholder="(hasRole('admin')) ? 'Codice o studente' : 'Cerca per codice'"/>
            </div>

            <div class="col-md-2">
                <b-form-group id="templateId" label="" label-for="templateId" description="">
                    <b-form-select v-model="filter.templateId" @change="search()">
                        <option value="">Tutti i prodotti</option>
                        <option v-for="template in templates" v-bind:value="template.id" :key="template.id">
                            {{ template.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>

            <div class="col-sm-2">
                <date-picker v-model="filter.range" range :lang="lang" value-type="format" @change="search()" v-if="hasRole('admin')"></date-picker>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content text-right">
            <button class="btn btn-outline-secondary btn-sm" @click="toggleExpired">

                <span v-if="!filter.expired"><b-icon icon="clock"/> Mostra scadute</span>
                <span v-else>Nascondi scadute</span>
            </button>
        </div>

        <div class="content">

            <div class="index" :class="{ 'opened': (currentId == -1) }">

                <div class="card">
                    <b-row>
                        <b-col sm="3">
                            <h5>Licenze Acquistate</h5>
                            <h2>{{ licenses.length }}</h2>
                        </b-col>
                        <b-col sm="3">
                            <h5>Attivate <b-icon icon="patch-check-fill" variant="success"/></h5>
                            <h2>{{ activatedLicense.length }}</h2>
                        </b-col>
                        <b-col sm="3">
                            <h5>Da attivare</h5>
                            <h2>{{ licenses.length - activatedLicense.length }}</h2>
                        </b-col>
                        <b-col sm="3">
                            <h5>Scadute</h5>
                            <h2>{{ expiredLicense.length }}</h2>
                        </b-col>
                    </b-row>
                </div>
                <br/>

                <div class="row">
                    <div class="col-6">
                        <b-button variant="link" @click="removeLicenses" v-if="selected.length > 0" style="color: red"><b-icon icon="trash"/>Cancella le licenze selezionate</b-button>
                    </div>
                    <div class="col-6 text-right">
                        <b-button variant="link" @click="downloadCsv" v-if="hasRole('admin')"> <b-icon icon="download"/> Scarica il CSV</b-button>
                    </div>
                </div>

                <div class="card">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="licenses.length"
                        :per-page="perPage"
                        limit="15"
                        align="right"
                        v-if="licenses.length > perPage"
                    ></b-pagination>

                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th>
                                <label class="form-checkbox">
                                    <input type="checkbox" v-model="selectAll" @click="select">
                                    <i class="form-icon"></i>
                                </label>
                            </th>
                            <th scope="col">Nome</th>
                            <th scope="col" class="text-center">Attivata</th>
                            <th scope="col" class="text-center">Codice</th>
                            <th scope="col" style="width:280px">Scadenza</th>
                            <th scope="col">Studente</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': item.id == currentId, 'text-muted': (isExpired(item)) }"
                            v-for="item in lists"
                            :key="item.id"
                            @click="setActiveLicense(item)"
                        >
                            <td>
                                <label class="form-checkbox" @click.stop="">
                                    <input type="checkbox" :value="item.id" v-model="selected">
                                    <i class="form-icon"></i>
                                </label>
                            </td>
                            <td>{{ item.name }}</td>
                            <td :class="{ 'text-success': (item.activated && item.profile), 'text-warning': (item.activated && !item.profile), 'text-muted': (!item.activated || isExpired(item))  }" class="text-center">
                                <b-icon :icon="(item.activated) ? 'patch-check-fill' : 'dash'"/></td>
                            <td class="text-center">
                                <span class="license-code" :class="{'activated': (item.activated)}" v-if="item.activated || hasRole('admin')">{{ item.code }}</span>
                            </td>
                            <td>{{ formatDate(item.expireAt) }}</td>
                            <td>{{ (item.profile) ? item.profile.firstname+' '+item.profile.lastname : '' }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="licenses.length"
                        :per-page="perPage"
                        limit="15"
                        align="right"
                        v-if="licenses.length > perPage"
                    ></b-pagination>

                    <p v-if="licenses.length == 0">Ancora nessuna licenza acquistata</p>
                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <product-license-view :license="currentLicense" :key="currentLicense.id"
                                                   v-on:updated-template="refreshCurrentLicense($event)"
                                                   v-on:updated-license="refreshCurrentLicense($event)"
                                                   v-on:edit-template="setEdit()"
                                                   v-on:generate-license="setGenerate()"
                                                   v-on:closed-editor="closeEditor()"/>
                </div>
            </div>


        </div>
    </div>

</template>

<script>
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";
import ProductLicenseView from "@/components/product_license/ProductLicenseView";
import SearchBar from "@/components/layout/SearchBar";
import moment from 'moment';
import ProductLicenseTemplateDataService from "@/components/product_license_template/ProductLicenseTemplateDataService";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/it';

export default {
    name: "product-license-index",
    components: {ProductLicenseView, SearchBar, DatePicker},
    data() {
        return {
            licenses: [],
            templates: [],
            activatedLicense: [],
            expiredLicense: [],
            currentLicense: null,
            currentId: -1,
            edit: false,
            generate: false,
            selected: [],
            selectAll: false,
            perPage: 25,
            currentPage: 1,
            filter: {
                code: '',
                expired: false,
                templateId: ''
            },
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1,
                },
                monthBeforeYear: true,
            }
        };
    },
    computed: {
        lists () {
            const items = this.licenses
            // Return just page of items needed
            return items.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            )
        },
        totalRows () {
            return this.licenses;
        }
    },
    methods: {
        retrieveLicenses() {
            ProductLicenseDataService.getAll()
                .then(response => {
                    this.licenses = response.data;
                    this.updateSummary();
                })
                .catch(e => {
                    console.log(e);
                });
        },
        toggleExpired() {
            this.filter.expired = !this.filter.expired
            this.currentLicense = null;
            this.currentId = -1;
            this.search();
        },
        refreshList() {
            this.retrieveLicenses();
            this.currentLicense = null;
            this.currentId = -1;
        },

        refreshCurrentLicense(itemLicense) {
            this.licenses.forEach(function (item) {
                if (item.id == itemLicense.id) {
                    item.name = itemLicense.name;
                    item.activated = itemLicense.activated;
                    item.code = itemLicense.code;
                    item.expireAt = itemLicense.expireAt;
                }
            });
            this.currentLicense = itemLicense;
            this.edit = false
        },

        setActiveLicense(item) {
            this.currentLicense = item;
            this.currentId = item.id;
            this.edit = false;
        },

        setEdit() {
            this.edit = true;
        },
        setGenerate() {
            this.generate = true;
        },

        removeLicenses() {
            ProductLicenseDataService.deleteMany({ids: this.selected})
                .then(response => {
                    this.refreshList();
                    this.selected = [];
                    this.selectAll = false;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        searchByCode(code) {
            this.filter.code = code;
            this.search();
        },
        search() {
            this.currentLicense = null;
            this.currentId = -1;
            ProductLicenseDataService.findByFilter(this.filter)
                .then(response => {
                    this.licenses = response.data;
                    this.updateSummary();
                })
                .catch(e => {
                    console.log(e);
                });
        },
        resetFilter() {
            this.filter.code = '';
            this.filter.expired = false;
            this.filter.templateId = '';
            this.retrieveLicenses();
        },
        closeEditor() {
            if (this.edit == true) {
                this.edit = false
                return;
            }
            if (this.generate == true) {
                this.generate = false
                return;
            }
            this.currentLicense = null;
            this.currentId = -1;
        },
        formatDate(datetime) {
            if (datetime) return moment(datetime).format('DD/MM/YYYY HH:mm');
            else return '';
        },
        isExpired(item) {
            return (moment().isAfter(item.expireAt));
        },
        updateSummary() {
            this.activatedLicense = this.licenses.filter(item => item.activated == true);
            this.expiredLicense = this.licenses.filter(item => moment().isAfter(item.expireAt));
        },
        downloadCsv() {
            console.log(this.filter)
            ProductLicenseDataService.download(this.filter)
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data], {
                        type: "text/csv;charset=utf-8",
                    }));
                    let fURL = document.createElement('a');

                    fURL.href = fileURL;
                    let filename = 'Export licenze ' + this.$store.state.schoolName + '.csv';
                    fURL.setAttribute('download', filename);
                    document.body.appendChild(fURL);

                    fURL.click();
                })
                .catch(e => {
                    console.log(e);
                });
        },
        select() {
            this.selected = [];
            if (!this.selectAll) {
                for (let i in this.licenses) {
                    this.selected.push(this.licenses[i].id);
                }
            }
        }

    },
    mounted() {
        this.retrieveLicenses();
        ProductLicenseTemplateDataService.getAllBySchool(this.$store.state.schoolId)
            .then(response => {
                this.templates = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>

<style scoped>
.editor-edit.opened {
    top: 20vh;
}
.editor-edit {
    height: 80vh;
}
.editor-generate.opened {
    top: 50vh;
}
.editor-generate {
    height: 50vh;
}
.license-code {
    display: inline-block;
    width: 100px;
    padding: 4px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: rgba(205, 182, 29, 0.25);
    color: #5e5516;
    font-weight: bold;
    font-size: .9em;
}
.license-code.activated {
    background-color: #e0e0e0;
    color: #7e7e7e;
}
.table td, .table th {
    padding: 0.25rem;
}
</style>
