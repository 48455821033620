<template>
    <div>
        <div v-if="license && products.length > 0" class="card">
            <h1>Dettagli della licenza</h1>
            <dl class="">
                <dt class="">Nome</dt>
                <dd class="">{{ license.name }}</dd>

                <dt class="">Prodotto</dt>
                <dd class="">{{ products.find(p => p.id == license.edulabProductId).name  }}</dd>

                <dt class="" v-if="license.activated || hasRole('admin')">Codice</dt>
                <dd class="" v-if="license.activated || hasRole('admin')">
                    <span class="license-code" :class="{'activated': (license.activated)}">{{ (license.activated || hasRole('admin')) ? license.code : '' }}</span></dd>

                <dt class="" v-if="license.activated">Attivata il</dt>
                <dd class="" v-if="license.activated && license.registeredAt">{{ formatDate(license.registeredAt) }}</dd>
                <dd class="" v-if="license.activated && !license.registeredAt">Giorno dell'acquisto</dd>

                <dt class="" v-if="license.activated && this.license.profile">Dallo studente</dt>
                <dd class="" v-if="license.activated && this.license.profile">{{ license.profile.firstname }} {{ license.profile.lastname }}</dd>

                <dt class="" v-if="license.activated">Scadenza</dt>
                <dd class="" v-if="license.activated" :class="{ 'text-muted': (isExpired()) }">
                    <div v-if="!edit">
                        {{ (isExpired()) ? 'Scaduta il' : '' }}
                        {{ formatDate(license.expireAt) }}
                        <b-button v-if="license.expireAt && hasRole('admin')" variant="link" size="sm" @click="edit = true">
                            <b-icon icon="pencil"/>
                        </b-button>
                    </div>
                    <product-license-expiration v-else
                                            :product-license="license"
                                            v-on:closed-editor-renew="edit = false"
                                            v-on:updated-product-license="updatedProductLicense"/>
                </dd>

            </dl>
            <p v-if="license.activated" class="alert alert-success">Licenza attivata</p>
            <p v-else-if="errorMsg" class="alert alert-danger">{{ errorMsg }}</p>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <b-button variant="success" size="" @click="downloadPdf" v-if="!license.activated && hasRole('admin')">
                        <b-icon icon="file-earmark-arrow-down"/>
                        Scarica la tessera in PDF
                    </b-button>

                    <b-button variant="success" size="" @click="activateWithoutProfile" v-if="canBeActivated && hasRole('owner')">
                        <b-icon icon="check"/>
                        Attiva la licenza
                    </b-button>
                </div>
            </div>

        </div>
        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Carico...' }}</p>
        </div>
    </div>
</template>

<script>
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";
import ProductLicenseExpiration from "@/components/product_license/ProductLicenseExpiration";
import ProfileDataService from "@/components/profile/ProfileDataService";
import moment from 'moment';
import EduLabDataService from "@/components/product_license_template/EduLabDataService";
import {store} from "@/components/store";

export default {
    name: "product-license-view",
    components: {ProductLicenseExpiration},
    data() {
        return {
            currentProductLicense: null,
            canBeActivated: false,
            products: [],
            message: '',
            errorMsg: '',
            edit: false
        };
    },
    props: ["license"],
    methods: {
        downloadPdf() {
            ProductLicenseDataService.downloadPdf(this.license.id)
                .then(response => {
                    const blob = new Blob([response.data], { type: "application/pdf" });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = this.license.name +  " " + this.license.code + ".pdf";
                    link.click();
                })
                .catch(e => {
                    this.errorMsg = e.response.data.message;
                    console.log(e);
                });
        },
        activateWithoutProfile() {
            ProductLicenseDataService.activateWithoutProfile(this.license.id)
                .then(response => {
                    ProductLicenseDataService.get(this.license.id)
                        .then(response => {
                            this.currentProductLicense = response.data;
                            this.$emit('updated-license', this.currentProductLicense);
                        });
                })
                .catch(e => {
                    this.errorMsg = e.response.data.message;
                    console.log(e);
                });
        },
        closeEditor() {
            this.$emit('closed-editor');
        },
        formatDate(datetime) {
            return moment(datetime).format('DD/MM/YYYY HH:mm');
        },
        isExpired() {
            return (moment().isAfter(this.license.expireAt));
        },
        updatedProductLicense() {
            this.edit = false
            this.$emit('updated-product-license', this.currentProductLicense);
        }
    },
    mounted() {
        this.message = '';
        this.currentProductLicense = this.license;
        ProductLicenseDataService.isActivable(this.license.id)
            .then(data => {
                this.canBeActivated = true;
            })
            .catch(e => {
                this.errorMsg = e.response.data.message;
                console.log(e);
            });
        EduLabDataService.getAllProducts()
            .then(response => response.json())
            .then(data => {
                this.products = data;
            })
            .catch(e => {
                console.log(e);
            });
        if (this.license.profileId) {
            ProfileDataService.get(this.license.profileId)
                .then(response => {
                    this.license.profile = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        }

    }
};
</script>

<style scoped>
.action-bar {
    margin-top: 15px;
}
.collapsed-terms {
    height: 140px;
    overflow: hidden;
}
.terms-of-use {
    padding: 15px;
    border: 1px solid #eee;
}
.license-code {
    font-size: 1.5em;
    width: 150px;
    text-align: center;
}
</style>
